














import {Component, Vue} from "vue-property-decorator"
import {ICurrency} from "@/interfaces"

@Component
export default class SelectCurrency extends Vue {

	isActive(currency: ICurrency) {
		return this.$store.state.selectedCurrency === currency
	}

	setCurrency(currency: ICurrency) {
		this.$store.dispatch('setSelectedCurrency', currency)

		this.$router.push({
			name: 'invoiceCurrency',
			params: {
				currency: currency.symbol
			}
		})

        this.$store.dispatch('setStep', true)
	}

}
