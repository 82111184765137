


























































import {Component, Vue} from "vue-property-decorator"
import api from "@/api"
import moment from "moment"
import SelectCurrency from "@/components/steps/SelectCurrency.vue"
import SelectNetwork from "@/components/steps/SelectNetwork.vue"
import {ICurrency, INetwork} from "@/interfaces"
import Confirm from "@/components/steps/Confirm.vue"
import Process from "@/components/steps/Process.vue"
import Success from "@/components/steps/Success.vue"

@Component({
	components: {
		SelectCurrency, SelectNetwork, Confirm, Process, Success
	}
})
export default class Invoice extends Vue {

	get time() {
		return moment().locale('ru').format('lll')
	}

	nextStep() {
		this.$router.push({
			name: 'invoiceApprove',
			params: {
				currency: this.$store.state.selectedCurrency.symbol,
				network: this.$store.state.selectedNetwork.sysName,
				approve: 'approve'
			}
		})

		this.$store.dispatch('setStep', true)
	}

	prevStep() {
		if (this.$store.state.step === 2)
			this.$router.push({
				name: 'invoiceCurrency',
				params: {
					currency: this.$store.state.selectedCurrency.symbol
				}
			})

		if (this.$store.state.step === 4)
			this.$router.push({
				name: 'invoiceNetwork',
				params: {
					currency: this.$store.state.selectedCurrency.symbol,
					network: this.$store.state.selectedNetwork.sysName
				}
			})

		this.$store.dispatch('setStep', false)
	}

	mounted() {
		api.post('/api/info/pay', {
			project: this.$route.params.project,
			account: this.$route.params.account,
			paymentId: this.$route.params.paymentId,
			sum: this.$route.params.sum,
			description: this.$route.params.description,
      time: this.$route.params.time,
			hash: this.$route.params.hash
		}).then(({data}) => {
      if (!data.success) {
        this.$router.push({
          name: 'error',
          params: {
            message: data.message
          }
        })
        return
      }

			this.$store.dispatch('setPaymentData', data)

			if (this.$route.params.currency) {
				const currency = data.currencies.find((currency: ICurrency) => currency.symbol === this.$route.params.currency)
				if (currency) {
					this.$store.dispatch('setSelectedCurrency', currency)
					this.$store.dispatch('setStep', true)
				} else {
					this.$router.push({
						name: 'error',
						params: {
							message: `Валюта ${this.$route.params.currency} не найдена`
						}
					})
				}
			}

			if (this.$route.params.network) {
				const network = data.networks.find((network: INetwork) => network.sysName === this.$route.params.network)
				if (network) {
					this.$store.dispatch('setSelectedNetwork', network)
					this.$store.dispatch('setStep', true)
				} else {
					this.$router.push({
						name: 'error',
						params: {
							message: `Сеть ${this.$route.params.network} не найдена`
						}
					})
				}
			}

			if (this.$route.params.approve) {
				if (this.$route.params.approve === 'approve') {
					this.$store.dispatch('setStep', true)
				} else {
					this.$router.push({
						name: 'error',
						params: {
							message: `Возникла ошибка при проверке URL адреса`
						}
					})
				}
			}
		})
	}

}
