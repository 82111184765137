
























import {Component, Vue} from "vue-property-decorator"
import * as QRCode from "qrcode"
import {IMerchant} from "@/interfaces"
import api from "@/api"
import moment from "moment"
import socket from "@/socket"

@Component
export default class Confirm extends Vue {

	loading: boolean = true
	amount: string = ''
	wallet: string = ''
	time: string = ''

  copyWalletAddress() {
    const address = document.querySelector('#wallet_address')
    const range = document.createRange()
    const selection = document.getSelection()
    if (address)
      range.selectNodeContents(address)
    if (selection) {
      selection.removeAllRanges()
      selection.addRange(range)
    }
    navigator.clipboard.writeText(this.wallet)
  }

	mounted() {
		const merchant = this.$store.state.merchants.find((merchant: IMerchant) => merchant.currencyId === this.$store.state.selectedCurrency.id && merchant.networkId === this.$store.state.selectedNetwork.id)

		if (!merchant) {
			this.$router.push({
				name: 'error',
				params: {
					message: 'Указанный способ оплаты не найден'
				}
			})
		}

		api.post(`/api/pay/${this.$store.state.projectName}`, {
			account: this.$route.params.account,
			description: this.$route.params.description,
			paymentId: this.$route.params.paymentId,
			id: merchant.id,
			sum: this.$route.params.sum,
      time: this.$route.params.time,
			hash: this.$route.params.hash
		}).then(({data}) => {
			this.loading = false

			this.amount = data.amount
			this.wallet = data.wallet

			this.$store.dispatch('setSum', this.amount)

			socket.emit('subscribe', data.socket)

			setInterval(() => {
				if (moment().unix() >= data.timeExpire) {
					window.location.reload()
				}
				this.time = `${moment.utc(moment.unix(data.timeExpire).diff(moment())).format('H:mm:ss')}`
				this.$store.dispatch('setTime', data.timeExpire)
			}, 200)

			QRCode.toCanvas(document.querySelector('#qr'), data.wallet, {
				width: 250
			})
		})
	}

}
