import axios from "axios"
import router from "@/router"

const api = axios.create({
    
})

api.interceptors.response.use(async (response) => {
    if (response.data.success === false) {
        await router.push({
            name: 'error',
            params: {
                message: response.data.message
            }
        })
    }
    return response
}, async () => {
    await router.push({
        name: 'error',
        params: {
            message: 'Возникла ошибка при загрузке данных'
        }
    })
})

export default api
