
















import {Component, Vue} from "vue-property-decorator"
import api from "@/api"
import {IMerchant} from "@/interfaces"
import socket from "@/socket"

@Component
export default class Process extends Vue {

	stages: string[] = [
		'Создание платежа',
		'Обработка платежа',
		'Подтверждение платежа',
		'Платеж завершен'
	]
	time: string = ''

	isActive(index: number) {
		return this.$store.state.stage >= index + 1
	}

	mounted() {
		api.post('/api/check/stage', {
			account: this.$route.params.account,
			description: this.$route.params.description,
			paymentId: this.$route.params.paymentId,
			sum: this.$route.params.sum,
			projectName: this.$route.params.project,
      time: this.$route.params.time,
			hash: this.$route.params.hash,
			merchant: this.$store.state.merchants.find((merchant: IMerchant) => merchant.currencyId === this.$store.state.selectedCurrency.id && merchant.networkId === this.$store.state.selectedNetwork.id).id
		}).then(({data}) => {
			socket.emit('subscribe', data.socket)
			this.$store.dispatch('setStage', data.stage)
			this.$store.dispatch('setSum', data.amount)
		})
	}

}
