














import {Component, Vue} from "vue-property-decorator"
import {IMerchant, INetwork} from "@/interfaces"

@Component
export default class SelectNetwork extends Vue {

	get networks(): INetwork[] {
		const merchants = this.$store.state.merchants.filter((merchant: IMerchant) => merchant.currencyId === this.$store.state.selectedCurrency.id)
		return merchants.map((merchant: IMerchant) => this.$store.state.networks.find((network: INetwork) => network.id === merchant.networkId))
	}

	isActive(network: INetwork) {
		return this.$store.state.selectedNetwork === network
	}

	setNetwork(network: INetwork) {
		this.$store.dispatch('setSelectedNetwork', network)

		this.$router.push({
			name: 'invoiceNetwork',
			params: {
				currency: this.$store.state.selectedCurrency.symbol,
				network: network.sysName
			}
		})

		this.$store.dispatch('setStep', true)
	}

}
