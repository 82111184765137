import Vue from 'vue'
import Vuex from 'vuex'
import {ICurrency, INetwork, MainState} from "@/interfaces"

Vue.use(Vuex)

export default new Vuex.Store<MainState>({
    state: {
        projectName: null,
        currencies: [],
        networks: [],
        merchants: [],
        step: 1,
        selectedCurrency: null,
        selectedNetwork: null,
        error: null,
        stage: 1,
        timeExpire: 0,
        projectUrl: null,
        sum: null
    },
    mutations: {
        setPaymentData(store, data) {
            store.projectName = data.name
            store.currencies = data.currencies
            store.networks = data.networks
            store.merchants = data.merchants
            store.projectUrl = data.url
        },
        setSelectedCurrency(store, currency: ICurrency) {
            store.selectedCurrency = currency
            store.selectedNetwork = null
            store.error = null
        },
        setSelectedNetwork(store, network: INetwork) {
            store.selectedNetwork = network
            store.error = null
        },
        incrementStep(store) {
            store.step++
        },
        decrementStep(store) {
            store.step--
        },
        setError(store, error: string) {
            store.error = error
        },
        setStage(store, stage: number) {
            store.stage = stage
            if (stage === 4) store.step++
        },
        setTime(store, time: number) {
            store.timeExpire = time
        },
        setSum(store, sum: string) {
            store.sum = sum
        }
    },
    actions: {
        setPaymentData({commit}, data) {
            commit('setPaymentData', data)
        },
        setSelectedCurrency({commit}, currency: ICurrency) {
            commit('setSelectedCurrency', currency)
        },
        setSelectedNetwork({commit}, network: INetwork) {
            commit('setSelectedNetwork', network)
        },
        setStep({commit}, mode: boolean) {
            commit(mode ? 'incrementStep' : 'decrementStep')
        },
        setError({commit}, error: string) {
            commit('setError', error)
        },
        setStage({commit}, stage: number) {
            commit('setStage', stage)
        },
        setTime({commit}, time: number) {
            commit('setTime', time)
        },
        setSum({commit}, sum: string) {
            commit('setSum', sum)
        }
    },
    modules: {}
})
