import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import Invoice from "@/views/Invoice.vue"
import Error from "@/views/Error.vue"
import Main from "@/views/Main.vue"

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        component: Main
    },
    {
        path: '/pay/:project/:paymentId/:account/:sum/:description/:time/:hash',
        component: Invoice,
        name: 'invoice'
    },
    {
        path: '/pay/:project/:paymentId/:account/:sum/:description/:time/:hash/:currency',
        component: Invoice,
        name: 'invoiceCurrency'
    },
    {
        path: '/pay/:project/:paymentId/:account/:sum/:description/:time/:hash/:currency/:network',
        component: Invoice,
        name: 'invoiceNetwork'
    },
    {
        path: '/pay/:project/:paymentId/:account/:sum/:description/:time/:hash/:currency/:network/:approve',
        component: Invoice,
        name: 'invoiceApprove'
    },
    {
        path: '/error',
        name: 'error',
        component: Error,
        props: true
    },
    {
        path: '*',
        redirect: 'error'
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
