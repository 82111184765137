



















import {Component, Prop, Vue} from "vue-property-decorator"
import moment from "moment"

@Component
export default class Error extends Vue {

	@Prop({default: 'Страница не найдена'}) message!: string

}
